import OurServiceSection from '../../Components/OurServiceSection'
import UnbeatableSection from '../../Components/UnbeatableSection'
import './styles.scss'

import titleIcon1 from '../../Assets/Images/title-icon-1.png'
import about1 from '../../Assets/Images/about-1.jpg'

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="about-us__top">
        <h1>About Us</h1>
      </div>

      <div className="about-us__content">
        <section>
          <div className="section1-col">
            <span className="title-text">
              <img src={titleIcon1} alt="" />
              Who We Are?
            </span>

            <h4>25 years of experience in Logistics services </h4>

            <p>
              We’re one of the leading independent shipping and logistics
              providers. Trusted since 1933 by some of the world’s leading
              companies, we provide liner
              <br /> and port agency services, freight forwarding by sea, air
              and road, warehousing & distribution services and end to end
              supply chain management.
            </p>
          </div>

          <div className="section1-col">
            <img src={about1} alt="" />
          </div>
        </section>

        <section>
          <div className="testimonial">
            <h4>Mission</h4>

            <p>
              As a client-centered Logistics Company, our mission is to offer
              high-performance solutions that would exceed customer expectations
              through high quality service, cost control, continuous
              up-gradation of technology and development of our human capital.
            </p>
          </div>

          <div className="testimonial">
            <h4>Vision</h4>

            <p>
              It shall be the endeavor of Oanda Logistics to
              surpass customer expectations through Innovation, Creativity and
              an uncompromising commitment to Quality, Transparency and
              Integrity in offering total logistic solutions.
            </p>
          </div>
        </section>

        <UnbeatableSection />

        <OurServiceSection />
      </div>
    </div>
  )
}

export default AboutUs
